<template>
    <div v-if="isModalLoaded" class="v-modal clicked">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide(modalName)"> ×</button>
        <h1 class="clicked__title">{{modalTitle}}</h1>
        <ul class="clicked__list">
            <li v-for="user of usersArray" class="clicked__item">
                <router-link :to="user.link" target="_blank" class="clicked__avatar">
                    <avatar :src="user.image_zoom_out"/>
                </router-link>
                <div class="clicked__wrap">
                    <router-link :to="user.link" class="clicked__name">{{user.full_name}}</router-link>
                    <span class="clicked__position">{{user.positions}}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import session from '@/api/session';
    import Avatar from "../Avatar";
    export default {
        name: "UsersModal",
        data() {
            return {
                modalName: 'UsersModal',
                usersList: [],
                isModalLoaded: false,
            }
        },
        components: {
          Avatar
        },
        props: {
            api: {
                type: String,
                required: true
            },
            modalTitle: {
                type: String,
                required: true
            }
        },
        computed: {
          usersArray() {
              if (this.usersList[0].user) {
                  return this.usersList.map(item => item.user);
              }
              return this.usersList;
          }
        },
        async mounted() {
            const request = session.get(this.api);
            const response = await request;
            this.usersList = response.data;
            this.isModalLoaded = true;
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"
    .clicked
        padding: 35px 0 35px 40px
        &__title
            font-size: 1.25rem
            font-weight: 600
        &__list
            list-style-type: none
            padding:
                left: 0
                bottom: 30px
            margin-top: 30px
            overflow-y: auto
            max-height: 100%
        &__item
            display: flex
            padding-right: 30px
            &:nth-child(n+2)
                margin-top: 20px
        &__wrap
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-left: 10px
        &__name
            color: #000
            font-weight: 600
            font-size: 1rem
        &__position
            color: #A4AEBD
            font-size: 1rem


</style>
